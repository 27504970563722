.about-container {
    padding: 1rem 3rem 3rem 3rem;
}

.about-container .button {
    margin: 1rem;
}

.about-text {
    line-height: 3rem;
    margin: auto;
    max-width: 66ch;
    padding: 1rem 0;
    text-align: left;
    text-indent: 2ch;
}