.sponsor-card {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;

    padding: 3rem 2rem 3rem;
}

.sponsor-hero-container {
    padding-bottom: 3rem;
}

.sponsor-logo {
    width: 280px;
}

.sponsor-logo, .sponsor-title {
    padding: 3rem 1rem;
}

.sponsor-title {
    width: 600px;
}

.sponsor-title h2 {
    margin: 0.25rem 0;
}

@media screen and (max-width: 840px) {
    .sponsor-card {
        align-items: center;
        flex-direction: column;
    }

    .sponsor-title {
        display: inline;
        width: 60%;
    }
}