.band-header {
  padding-top: 2rem;
}

.band-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.band-name h3 {
  margin-bottom: 0;
}

.band-city {
  font-size: 24px;
}

.band-table-entry {
  padding: 1rem 0;
}