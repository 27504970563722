#festival-header {
    margin-top: 0;
}

#location-table {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2.location-name {
    margin-bottom: 0;
}

.band-container {
    padding: 2rem 0;
}

.location-and-schedule-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding-top: 2rem;
}

.festival-image-container {
    max-width: 45%;
    padding: 0.5rem;
}

.festival-image-container img {
    width: 100%;
}

.location-table-container {
    padding: 4rem 0;
}

.festival-time {
    font-size: 24px;
}

.location-table-row {
    padding: 1rem 0;
}

td {
    padding: 2rem 0;
}

h1 {
    margin: 0;
    padding-bottom: 1rem;
}

.past-festival-header {
    padding-top: 5rem;
}

@media screen and (max-width: 840px) {
    .festival-image-container {
        max-width: 100%;
    }
}