.button {
  border: 0;
  border-radius: 16px;
  font-family: avenirNextCondensed;
  font-size: 26px;
  font-weight: light;
  letter-spacing: 1px;
  min-height: 60px;
}

@media screen and (min-width: 481px) {
  .button {
    min-width: 290px;
  }
}

@media screen and (max-width: 480px) {
  .button {
    margin: auto;
    display: block;
    max-width: 80vw !important;
    min-width: 160px;
    width: 80%;
  }
}