.about-principles-container {
    background: var(--community);
    margin: 0 0 3rem 0;
    padding: 3rem;
}

.about-principles-container .button {
    margin: 1rem 0;
}

.about-principles-container h2 {
    margin: 4rem auto 2rem;
}

.about-principles-list {
    margin: auto;
    max-width: 60ch;
    text-align: left;
}

.about-principles-list li {
    font-size: 20px;
    line-height: 2rem;
    margin: 2.5rem 0;
}

.about-principles-list em {
    display: block;
    font-size: 24px;
    font-weight: bold;
    margin: 0.5rem 0;
}

#influences {
    margin: 0;
}