#mc-embedded-subscribe-form {
    margin: auto;
    padding: 64px;
}

#mce-error-response {
    display: none;
}

#mce-error-response {
    display: none;
}

.input-helper {
    display: none;
}

.connect-form {
    padding: 2rem;
    width: 360px;
}

.connect-form .button {
    border-radius: 8px;
    display: block;
    margin: auto;
    margin-top: 24px;
    min-height: 48px;
    min-width: 160px !important;
    text-transform: uppercase;
}

.connect-form p {
    font-family: avenir;
    padding: 12px 6px;
}

.form-container {
    background: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.radio-container {
    width: 320px;
}

.radio-selection {
    margin: 12px auto;
}

.radio-selection input {
    height: 20px;
    width: 20px;
}

.radio-selection label {
    padding-left: 8px;
}

.required {
    font-size: 16px;
}

.text-input-container {
    text-align: left;
}

.text-input-container input {
    background: var(--community);
    border: 1px solid var(--nature);
    border-radius: 8px;
    font-size: 24px;
    padding: 4px;
    width: 98%;
}

.text-input-container input:not(:last-child) {
    display: block;
}

.text-input-container label {
    font-size: 16px;
}

@media screen and (max-width: 1080px) {
    #mc-embedded-subscribe-form {
        padding: 32px;
    }
}

@media screen and (max-width: 840px) {
    #mc-embedded-subscribe-form {
        padding: 64px;
    }

    .form-container {
        align-items: center;
        flex-direction: column;
    }

    .text-input-container {
        width: 95%;
    }
}

@media screen and (max-width: 640px) {
    #mc-embedded-subscribe-form {
        padding: 32px;
    }
}