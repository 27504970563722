#title {
  font-family: avenir-next-condensed;
  font-size: 28px;
  padding: 8px 20px 8px 16px;
}

.nav-container {
  float: right;
  padding-right: 20px;
}

.topnav {
    overflow: hidden;
  }
  
  .topnav a {
    font-family: kopiusBold;
    float: left;
    display: block;
    text-align: center;
    padding: 14px 12px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 17px;
  }

  .topnav button {
    color: var(--indigo);
    height: 32px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 24px;
    margin: 8px;
    width: 32px;
  }
  
  .topnav a:hover {
    background-color: var(--brass);
  }
  
  .topnav .icon {
    display: none;
  }

@media screen and (max-width: 640px) {

  .nav-container {
    float: none;
    padding-right: 0;
  }

  .topnav a:first-child:hover {
    background: none;
  }

    .topnav a:not(:first-child) {
      display: none;
      border-top: 0.125rem solid var(--community);
    }
    .topnav button.icon {
      float: right;
      display: block;
    }
  }
  
@media screen and (max-width: 640px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive button.icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  }