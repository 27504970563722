.home-link {
    font-style: italic;
    background: var(--brass);
    padding: 0.125rem 0.5rem;
}

.home-link:hover {
    text-decoration: underline !important;
    text-underline-offset: 5px;
}

#home-link-container {
    padding: 2rem 0;
}

#home-link-container h2 {
    margin-bottom: 0;
}

.home-link-entry {
    margin: 3rem 0;
}

.home-video {
    margin: 0 0.5rem;
}

#home-video-container {
    background: var(--community);
    padding: 4rem 1rem;
}

#home-video-container h2 {
    margin-top: 0;
}

.media-name {
    font-weight: bold;
    margin: 0.5rem 0;
}

@media screen and (min-width: 1201px) {
    #home-video-container iframe {
        width: 560px;
        height: 320px;
    }
}

@media screen and (max-width: 1200px) {
    #home-video-container iframe {
        width: 980px;
        height: 560px;
    }
}

@media screen and (max-width: 1080px) {
    #home-video-container iframe {
        width: 700px;
        height: 400px;
    }
}

@media screen and (max-width: 840px) {
    #home-video-container iframe {
        width: 560px;
        height: 320px;
    }
}

@media screen and (max-width: 600px) {
    #home-video-container iframe {
        width: 420px;
        height: 240px;
    }
}

@media screen and (max-width: 480px) {
    #home-video-container iframe {
        width: 280px;
        height: 160px;
    }
}