#cta {
    padding: 0 2rem;
}

#cta p {
    margin: auto;
    max-width: 60ch;
    margin: 36px auto;
}

.cta-button-group {
    margin: 36px auto;
}