#footer {
    background: var(--nature);
    color: var(--community);
    padding: 0 10%;
}

#footer a {
    color: var(--community);
}

#footer a:hover {
    background-color: var(--brass);
    color: var(--indigo);
  }

.footer-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.footer-section {
    border-left: 1px solid var(--community);
    list-style-type: none;
    padding: 0 16px;
    min-width: 220px;
    text-align: left;
    font-size: 20px;
}

.footer-section a {
    display: block;
}

.footer-section li a {
    padding: 8px;
}

.footer-section-title {
    font-weight: bold;
    font-size: 24px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    letter-spacing: 1px;
    padding: 8px 0;
}

@media screen and (max-width: 1080px) {
    .footer-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer-section {
        width: 40%;
    }
}

@media screen and (max-width: 640px) {

    .footer-section {
        width: 100%;
    }
}