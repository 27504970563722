.home-support-container {
    padding: 24px 0 0 0;
    margin-bottom: -10px;
}

@media screen and (max-width: 1080px) {
    .home-support-container .button {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 640px) {
    .home-support-container .button {
        margin-bottom: 16px;
    }
}