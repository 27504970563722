* {
  box-sizing: content-box;
}

:root {
  --brass: #D4B839;
  --community: #EBEEEF;
  --expression: #FF7276;
  --indigo: #292A69;
  --nature: #6A8083;
}

@font-face {
  font-family: avenirNextCondensed;
  src: url(./media/font/Avenir-Next-Condensed.ttc);
}

@font-face {
  font-family: avenir;
  src: url(./media/font/Avenir.ttc);
}

@font-face {
  font-family: kefa;
  src: url(./media/font/Kefa.ttc);
}

@font-face {
  font-family: kopiusBold;
  src: url(./media/font/Kopius-Bold.ttf);
}

#root {
  max-width: 1280px;
  background: rgb(255, 255, 255);
  color: var(--indigo);
  font-family: avenir;
  font-size: 27px;
  margin: auto;
  text-align: center;
}

#root a {
  color: var(--indigo);
  text-decoration: none;
}

.bg-brass {
  background: var(--brass);
  color: var(--indigo);
}

.bg-clear {
  background: none;
  border: none;
  color: inherit;
}

.bg-community {
  background: var(--community);
  color: var(--indigo);
}

.bg-expression {
  background: var(--expression);
}

.bg-indigo {
  background: var(--indigo);
  color: var(--community);
}

.bg-nature {
  background: var(--nature);
  color: var(--community);
}

.hidden {
  display: none;
}

body {
  background-color: var(--indigo);
  overflow-x: hidden;
}

h1 {
  font-family: kopiusBold;
}

h2 {
  font-family: kopius;
}

@media screen and (max-width: 1080px) {
  .resize-header {
      font-size: 48px !important;
  }
}

@media screen and (max-width: 840px) {
  .resize-header {
      font-size: 32px !important;
  }
}