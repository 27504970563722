#about-team-container {
    margin-top: 3rem;
    padding-top: 1rem;
}

#team-list {
    list-style: none;
    padding: 0;
}

.team-member-entry {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.team-member-title {
    text-align: right;
    font-style: italic;
}

.team-member-name {
    text-align: left;
    font-weight: bold;
}

.team-member-title, .team-member-name {
    width: 25rem;
    padding: 0 1rem;
}

.pronouns {
    font-weight: initial;
}

@media screen and (max-width: 640px) {

    .team-member-entry {
        flex-direction: column;
        align-items: center;
    }

    .team-member-title, .team-member-name {
        padding: 0;
        text-align: center;
        width: 20rem;
    }

}